export default {
  "Forgot your password ?": "Mot de passe oublié",
  "Sign In": "Se connecter",
  "Email": "Email",
  "Password": "Mot de passe",
  "Enter your password": "Entrer votre mot de passe",
  "Enter your email": "Entrer votre adresse email",
  "Loading ...": "Chargement ...",
  "This user/password pair does not exist ": "Ce couple utilisateur / mot de passe n'existe pas",
  "An unexpected error has occurred": "Une erreur inattendue est survenue",
  "Let's democratize low carbon buildings": "Prêts à décarboner ?",
  "Energy": "Energie",
  "Comfort": "Confort",
  "Forgot Password": "Mot de passe oublié",
  "If this email exists, we have sent an email to him just now": "Si cet email existe, nous lui avons envoyé un mail à l'instant"
};
