import Loading from "@src/common/components/is-loading";
import { RootState } from "@src/store";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TemperatureAverageChart from "../charts/temperature-average-chart";
import TemperatureDistributionChart from "../charts/temperature-distribution-chart";
import TemperaturePeriodAverage from "../charts/temperature-period-average";
import AnalysisFilter from "../filters/analysis-filter";
import {updateURL} from "@src/common/utils";
import Slider, {Settings} from "react-slick";
import ButtonExport from "../buttons/button-export";
import Papa from "papaparse";
import {useTranslation} from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

export interface Distribution {
  _id: number,
  occupied: number,
  unoccupied: number,
  weekend: number,
  name: string,
  [key: string]: string | number;
}

export interface Average {
    name: number,
    occupied: number,
    unoccupied: number,
    weekend: number,
    [key: string]: number;
}

export interface PeriodAverage {
    occupied: number,
    unoccupied: number,
    weekend: number,
    [key: string]: number;
}

interface AnalysisResponse {
  temperature_distributions: Distribution[],
  temperature_averages: Average[],
  temperature_period_average: PeriodAverage
}

export default function SensorAnalysis() {
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [data, setData] = useState<AnalysisResponse>();
  const [hasError, setError] = useState(false);
  const [params] = useSearchParams();

  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const analysis = useSelector((state: RootState) => state.dashboard.analysis);
  const selected = useSelector((state: RootState) => state.dashboard.selected);

  const settings: Settings = {
    dots: true,
  };

  const { start, end } = analysis.filter.period;

  const onExportHandler = () => {
    setIsExporting(true);
    axios(`/api/v1/comfort/dashboard/export/analysis?precision=1&start=${moment(start).format("YYYY-MM-DD")}
      &end=${moment(end).format("YYYY-MM-DD")}
      &type=${analysis.filter.type}
      &step=${analysis.filter.step}
      &deveui="${analysis.filter.sensors.join(",")}"
    `).then(response => {
      const dataParsed = Papa.unparse(response?.data ?? [], {delimiter: ";"});
      const csvData = new Blob(["\uFEFF" + dataParsed], {type: "text/csv;charset=utf-8;"});
      const url = URL.createObjectURL(csvData);
      const link = document.createElement("a");
      link.href = url;
      link.download = "dashboard_comfort_series.csv";
      link.click();
      URL.revokeObjectURL(url);
    })
      .finally(() => setIsExporting(false));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("view", "analysis");
    updateURL(searchParams);
  }, []);

  useEffect(() => {
    const hasSensors = !!analysis.filter.sensors.filter(s => s !== undefined).length;
    if (!hasSensors) {
      setData({
        temperature_distributions: [],
        temperature_averages: [],
        temperature_period_average: {occupied: 0, unoccupied: 0, weekend: 0}
      });
      return;
    }

    setError(false);
    setIsLoading(true);
    axios
      .get(
        `/api/v1/comfort/dashboard/analysis?start=${moment(start).format("YYYY-MM-DD")}
          &end=${moment(end).format("YYYY-MM-DD")}
          &type=${analysis.filter.type}
          &step=${analysis.filter.step}
          &deveui="${analysis.filter.sensors.join(",")}"
          &building=${params.get("building")}
          `
      )
      .then((res) => setData(res.data))
      .catch(error => {
        setError(true);
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [selected, analysis]);

  const lang = document.documentElement.lang;
  const startDate = new Date(analysis.filter.period.start).toLocaleDateString(lang);
  const endDate = new Date(analysis.filter.period.end).toLocaleDateString(lang);

  return (
    <div className="row">
      <AnalysisFilter />
      <div className="d-flex flex-column col col-8 m-auto">
        {
          hasError ? (
            <h2 className="text-center text-danger">
              {t("Error during data recovery")}
            </h2>
          ) : (
            <>
              <div
                className="col col-8 mb-3 mt-3 d-flex align-items-center justify-content-start"
                style={{minHeight: "55px"}}>
                <ButtonExport onExportHandler={onExportHandler}/>
                {isExporting ? <Loading/> : <></>}
              </div>
              <div className="chart-container">
                <h2 className="graph-title">
                  {t("Temperature distribution")}<br/>
                  {t("from")}&nbsp;<span>{startDate}</span>
                    &nbsp;{t("to")} <span>{endDate}</span>
                </h2>
                {
                  isLoading ?
                    <Loading/>
                    :
                    <TemperatureDistributionChart
                      data={data?.temperature_distributions}/>
                }
              </div>
              <Slider {...settings} className="mb-3">
                <div className="chart-container">
                  <h2 className="graph-title">
                    {t("Average daily temperatures")}<br/>
                    {t("from")}&nbsp;<span>{startDate}</span>
                      &nbsp;{t("to")} <span>{endDate}</span>
                  </h2>
                  {
                    isLoading ?
                      <Loading/>
                      :
                      <TemperatureAverageChart
                        data={data?.temperature_averages}/>
                  }
                </div>
                <div>
                  <div className="chart-container">
                    <h2 className="graph-title">
                      {t("Average temperatures for the entire period")}<br/>
                      <span>{startDate}</span> - <span>{endDate}</span>
                    </h2>
                    {
                      isLoading ?
                        <Loading/>
                        :
                        <TemperaturePeriodAverage
                          data={data?.temperature_period_average}/>
                    }
                  </div>
                </div>
              </Slider>
            </>
          )
        }
      </div>
    </div>
  );
}
