import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  authTranslation, metrologyTranslation, dashboardTranslation
} from '@src/app/locales/fr';

const resources = {
  fr: {
    auth: authTranslation,
    metrology: metrologyTranslation,
    dashboard: dashboardTranslation,
  }
};

i18n
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    ns: ["auth", "metrology", "dashboard"],
    defaultNS: "auth",
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;