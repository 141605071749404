import { Equipment } from "@src/common/api";
import { SelectOption } from "@src/common/select";
import { removeDuplication } from "@src/common/utils";
import { useEffect, useState } from "react";
import { MultiValue } from "react-select";
import SensorSelect from "./sensor-select";
import _ from "lodash";
import {useTranslation} from "react-i18next";


const updateURL = (params: URLSearchParams) => {
  const newUrl = `${window.location.pathname}?${params.toString()}`;
  if (window.history.pushState) {
    window.history.pushState({path: newUrl}, "", newUrl);
  } else {
    window.location.href = newUrl;
  }
};

export default function SensorsFilters({allEquipments, onSelectFilter}: Props) {
  const {t, i18n} = useTranslation("dashboard");

  useEffect(() => {
    i18n.changeLanguage(document.documentElement.lang);
  }, []);

  const allOptions = generateGroupedOption(allEquipments, t);

  const [buildingSelection, setBuildingSelection] = useState<MultiValue<SelectOption>>();
  const [floorSelection, setFloorSelection] = useState<MultiValue<SelectOption>>();
  const [roomTypeSelection, setRoomTypeSelection] = useState<MultiValue<SelectOption>>();
  const [devEUISelection, setDevEUISelection] = useState<MultiValue<SelectOption>>();
  const [areaSelection, setAreaSelection] = useState<MultiValue<SelectOption>>();
  const [tenantSelection, setTenantSelection] = useState<MultiValue<SelectOption>>();
  const searchParams = new URLSearchParams(window.location.search);
  const shouldFilterCollapsed = Object.keys(JSON.parse(searchParams.get("filters") ?? "{}")).length == 0;

  const filterOptionByEquipment = (option: SelectOption) => {
    return allEquipments.find(equipment => {
      const values = Object.values(equipment.gui_informations);
      return values.includes(option.value) || equipment.name === option.value;
    });
  };

  const setSelectionByEquipment = (selection: MultiValue<SelectOption>, setSelection: (values: MultiValue<SelectOption>) => void) => {
    if (selection) {
      const newSelection = selection.filter(filterOptionByEquipment);
      setSelection(newSelection);
    }
  };

  useEffect(() => {
    const filters = JSON.parse(searchParams.get("filters") ?? "{}");
    if (Object.keys(filters).length == 0) {
      setSelectionByEquipment(buildingSelection, setBuildingSelection);
      setSelectionByEquipment(floorSelection, setFloorSelection);
      setSelectionByEquipment(roomTypeSelection, setRoomTypeSelection);
      setSelectionByEquipment(tenantSelection, setTenantSelection);
      setSelectionByEquipment(areaSelection, setAreaSelection);
      setSelectionByEquipment(devEUISelection, setDevEUISelection);
    } else {
      if (allEquipments === undefined) {
        return;
      }
      const selections: Options = {
        "building": [],
        "etage": [],
        "type": [],
        "tenant": [],
        "name": [],
        "zone": [],
      };
      const buildingFilter = filters.building;
      if (buildingFilter) {
        const buildings = buildingFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "building"};
        });
        selections["building"] = buildings;
        setSelectionByEquipment(buildings, setBuildingSelection);
      }
      const floorFilter = filters.etage;
      if (floorFilter) {
        const floors = floorFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "etage"};
        });
        selections["etage"] = floors;
        setSelectionByEquipment(floors, setFloorSelection);
      }
      const roomFilter = filters.type;
      if (roomFilter) {
        const roomsType = roomFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "type"};
        });
        selections["type"] = roomsType;
        setSelectionByEquipment(roomsType, setRoomTypeSelection);
      }
      const tenantFilter = filters.tenant;
      if (tenantFilter) {
        const tenants = tenantFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "tenant"};
        });
        selections["tenant"] = tenants;
        setSelectionByEquipment(tenants, setTenantSelection);
      }
      const areaFilter = filters.zone;
      if (areaFilter) {
        const areas = areaFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "zone"};
        });
        selections["zone"] = areas;
        setSelectionByEquipment(areas, setAreaSelection);
      }
      const deveuiFilter = filters.name;
      if (deveuiFilter) {
        const deveuis = deveuiFilter.split(",").map((b: any) => {
          return {label: b, value: b, color: "", type: "name"};
        });
        selections["name"] = deveuis;
        setSelectionByEquipment(deveuis, setDevEUISelection);
      }
      const filteredEquipments = filterEquipmentsByOptions("all", [], selections);
      onSelectFilter(filteredEquipments);
    }
  }, [allEquipments]);

  const filterEquipmentsByOptions = (type: string, options: MultiValue<SelectOption> = [], selections?: Options) => {
    let selection: Options = {
      "building": buildingSelection,
      "etage": floorSelection,
      "type": roomTypeSelection,
      "tenant": tenantSelection,
      "name": devEUISelection,
      "zone": areaSelection,
    };
    if (type === "all") {
      selection = _.cloneDeep(selections);
    } else {
      // Override current Selection.
      selection[type] = options;
    }
    let newEquipments: Equipment[] = allEquipments;
    for (const [type, selectedOptions] of Object.entries(selection)) {
      if (selectedOptions?.length) {
        newEquipments = newEquipments.filter((equipment) => {
          const value = type == "name" ? equipment.name : equipment.gui_informations[type];
          return (!!(selectedOptions.find(option => option.value === value)));
        });
      }
    }
    return newEquipments;
  };

  const setStateByType = (type: string, options: MultiValue<SelectOption>) => {
    switch (type) {
    case "building":
      setBuildingSelection(options);
      break;
    case "etage":
      setFloorSelection(options);
      break;
    case "name":
      setDevEUISelection(options);
      break;
    case "zone":
      setAreaSelection(options);
      break;
    case "type":
      setRoomTypeSelection(options);
      break;
    case "tenant":
      setTenantSelection(options);
      break;
    }

  };

  const onSelectHandler = (type: string, options: MultiValue<SelectOption>) => {
    setStateByType(type, options);
    const filteredEquipments = filterEquipmentsByOptions(type, options);
    onSelectFilter(filteredEquipments);

    const filters = JSON.parse(searchParams.get("filters") ?? "{}");
    const newValue = options.map(o => o.value).join(",");
    let newFilterType = filters;
    if (!newValue) {
      delete filters[type];
    } else {
      newFilterType = {...filters, [type]: newValue};
    }
    searchParams.set("filters", JSON.stringify(newFilterType));
    updateURL(searchParams);
  };

  return (
    <>
      <div className="panel-filters-sensors" id="filterSensors">
        <div className="panel panel-default mb-2">
          <div className="panel-heading1">
            <h4 className="panel-title1">
              <a className={`accordion-toggle ${shouldFilterCollapsed ? "collapsed" : ""}`}
                data-toggle="collapse"
                data-parent="#filterSensors"
                href="#collapsePeriod"
                aria-expanded={shouldFilterCollapsed}>{t("Sensors filters")}</a>
            </h4>
          </div>
        </div>
        <div id="collapsePeriod"
          className={`panel-collapse collapse ${shouldFilterCollapsed ? "" : "show"}`}
          role="tabpanel"
          aria-expanded="false">
          <div className="panel-body" style={{marginBottom: ".5rem"}}>
            <div className={"col"}>
              <div className="row">
                <SensorSelect
                  type="building"
                  placeHolder="Bâtiments / Lot"
                  bootstrapCol="4"
                  options={allOptions.building}
                  selectedOptions={buildingSelection}
                  onSelect={onSelectHandler}/>

                <SensorSelect
                  type="etage"
                  placeHolder="Etage"
                  bootstrapCol="4"
                  options={allOptions.etage}
                  selectedOptions={floorSelection}
                  onSelect={onSelectHandler}/>

                <SensorSelect
                  type="type"
                  placeHolder="Type de salle"
                  bootstrapCol="4"
                  options={allOptions.type}
                  selectedOptions={roomTypeSelection}
                  onSelect={onSelectHandler}/>
              </div>

              <div className="row mt-3">
                <SensorSelect
                  type="name"
                  placeHolder="DevEUI"
                  bootstrapCol="4"
                  options={allOptions.name}
                  selectedOptions={devEUISelection}
                  onSelect={onSelectHandler}/>

                <SensorSelect
                  type="zone"
                  placeHolder="Zone"
                  bootstrapCol="4"
                  options={allOptions.zone}
                  selectedOptions={areaSelection}
                  onSelect={onSelectHandler}/>

                <SensorSelect
                  type="tenant"
                  placeHolder="Locataire"
                  bootstrapCol="4"
                  options={allOptions.tenant}
                  selectedOptions={tenantSelection}
                  onSelect={onSelectHandler}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface Options {
    [key: string]: MultiValue<SelectOption>;
}

interface Props {
    allEquipments: Equipment[],
    onSelectFilter: (equipments: Equipment[]) => void;
}



function generateGroupedOption(equipments: Equipment[], t: any) {
  const labels = [
    {value: t("Building / Batch"), field: "building"},
    {value: t("Floor"), field: "etage"},
    {value: t("DevEUI"), field: "name"},
    {value: t("Zone"), field: "zone"},
    {value: t("Room Type"), field: "type"},
    {value: t("Tenant"), field: "tenant"}
  ];

  const groupedOptions: Options = {};

  labels.forEach(label => {
    const options = equipments?.map((equipment: Equipment) => {
      const data = label.field == "name" ? equipment.name : equipment.gui_informations[label.field];
      if (!data) return;
      return {
        value: data,
        label: data,
        color: "",
        type: label.field,
      };
    }).filter(o => !!o?.label);

    groupedOptions[label.field] = removeDuplication(options || []) as SelectOption[];
  });
  return groupedOptions;
}

