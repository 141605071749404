import { Spinner } from "@src/common/components/is-loading";
import { RootState } from "@src/store";
import axios from "axios";
import moment from "moment";
import {useContext, useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from "react-redux";

import {selectSensors} from "@src/store/reducers/metrology";

import { PlainButton } from "../buttons/button-export";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {ModalCharts} from "@src/dashboards/comfort";
import {BuildingContext} from "@src/dashboards/comfort/pages/comfort-dashboard";
import {useTranslation} from "react-i18next";
import _ from "lodash";

interface Data {
  ts: number;
  temperature: number;
  co2: number;
  [key: string]: any;
}

interface TableBodyDataProps {
  current: Data;
  previous: Data;
  label: string;
  deveui: string;
  zone: string;
  etage: string;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  minHeight: 600,
  maxHeight: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  overflow: "scroll",
  p: 4,
};

export default function SensorTableData({current, previous, label, deveui, zone, etage}: TableBodyDataProps) {
  const buildingContext = useContext(BuildingContext);
  const lang = document.documentElement.lang;
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [field, setField] = useState({value: "temperature", unit: "°C"});
  const [isLoading, setIsLoading] = useState(false);
  const [lastMessageDate, setLastMessageDate] = useState(null);
  const hoverCard = useRef(null);
  const {displaying} = useSelector((state: RootState) => state.dashboard.table);

  const [isModalOpen, setIsCurveModalOpen] = useState(false);
  const [buildingIdCurve, setBuildingIdCurve] = useState();
  const [equipmentCurve, setEquipmentCurve] = useState();

  const {t, i18n} = useTranslation("dashboard");
  const dispatch = useDispatch();
  
  useEffect(() => {
    const unit = displaying === "temperature" ? "°C" : "ppm";
    setField({value: displaying, unit});
    i18n.changeLanguage(document.documentElement.lang);
  }, [displaying]);


  const setHoverCardPosition = (event: any) => {
    const  { top, left } = document.querySelector(".card-dashboard-comfort").getBoundingClientRect();
    const {clientX, clientY, pageY} = event;
    const heightEventText = event.currentTarget.getBoundingClientRect().height;
    
    const table = document.getElementById("sensor-table-data").getBoundingClientRect();

    const {width, height} = hoverCard.current.getBoundingClientRect();
    
    let leftPositionValue = clientX - left;
    let topPositionValue = clientY - top + (heightEventText / 2);
    

    if (width + (clientX - left) > table.width) {
      const overflowValue = (width + (clientX - left)) - table.width;
      leftPositionValue -= overflowValue;
    }
    if (height + pageY > document.body.offsetHeight) {
      topPositionValue -= height + heightEventText;
    }

    hoverCard.current.style.left = `${leftPositionValue}px`;
    hoverCard.current.style.top = `${topPositionValue}px`;
  };
  
  const handleMouseEnter = (event: any) => {
    hoverCard.current.style.display = "block";
    setHoverCardPosition(event);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverCard.current.style.display = isClicked ? "block" : "none";
    setIsHovered(false);
  };

  const handleClick = (event: any) => {
    setIsClicked(true);
    if (isHovered) return;
    hoverCard.current.style.display = "block";
    setHoverCardPosition(event);
  };

  const handleClose = () => {
    hoverCard.current.style.display = "none";
    setIsClicked(false);
  };

  const getTemperaturePastille = (temperature: number, lastMessageDate: moment.Moment) => {
    const today = moment();
    const hourDelta = Math.abs(lastMessageDate.diff(today, "hours"));
      
    const SUMMER_MIN = 20;
    const SUMMER_MAX = 26;
    const WINTER_MIN = 19;
    const WINTER_MAX = 21;

    const currentYear = today.year();
    const isSummer = today.isBetween(`${currentYear}-04-15`, `${currentYear}-10-14`);

    const minTemp = isSummer ? SUMMER_MIN : WINTER_MIN;
    const maxTemp = isSummer ? SUMMER_MAX : WINTER_MAX;

    let className = "";
  
    if (!temperature || hourDelta >= 72) {
      return "grey";
    }
      
    if (temperature >= minTemp && temperature <= maxTemp) {
      className = "green";
    } else if (
      (temperature >= minTemp - 1 && temperature < minTemp) ||
        (temperature > maxTemp && temperature <= maxTemp + 1)
    ) {
      className = "yellow";
    } else {
      className = "red";
    }
    return className;
  };

  const getCo2Pastille = (co2: number, lastMessageDate: moment.Moment) => {
    const today = moment();
    const hourDelta = Math.abs(lastMessageDate.diff(today, "hours"));
    let classeName = "";
      
    if (!co2 || hourDelta >= 72) {
      return "grey";
    }
      
    if (co2 < 1100) {
      classeName = "green";
    } else if (co2 >= 1100 && co2 < 1500) {
      classeName = "yellow";
    } else if (co2 > 1500) {
      classeName = "red";
    }

    return classeName;
  };
  
  const getPastilleClass = (ts: number, data: number) => {
    if (!data) return "grey";

    const lastMessageDate = moment.unix(ts);
    let className = "";
    switch(field.value){
    case "temperature":        
      className = getTemperaturePastille(data, lastMessageDate);
      break;
    case "co2":
      className = getCo2Pastille(data, lastMessageDate);
    }
    return className;
  };

  const isTimestampOlderThan72Hours = (timestamp: number) => {
    const today = moment();
    const sensorData = moment.unix(timestamp);
    const hourDelta = Math.abs(today.diff(sensorData, "hours"));
    return hourDelta >= 72;
  };

  const fetchLastMessage = (deveui: string) => {
    setIsLoading(true);
    axios
      .get(
        `/api/v1/comfort/sensor/lastmessage?deveui="${[deveui]}"`
      )
      .then((res) => {
        if (!res.data?.ts) {
          setLastMessageDate("N/A");
          return;
        }
        const date_= moment(res.data.ts);
        const formattedDate = date_.locale(lang).format("LLL");
        setLastMessageDate(formattedDate);
      })
      .finally(() => setIsLoading(false));
  };

  const isOlderThan72Hours = isTimestampOlderThan72Hours(current.ts);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{width: "max-content", cursor: "pointer"}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}>
          <span className={"pastille " + getPastilleClass(current.ts, current[field.value])}></span>
          <span
            className="mx-1"
          >
            {(field.value === "temperature") ? 
              current[field.value]?.toFixed(1) || "--.--" 
              : 
              current[field.value] || "--.--"}
            {field.unit}
          </span>
        </div>
      </div>
      <div className="table-card-hover" ref={hoverCard}>
        <div className="close-icon-container">
          <p>Label&nbsp;:&nbsp;<span><b>{label}</b></span></p>
          {isClicked && (
            <button onClick={handleClose}>
              <i className="fa-regular fa-circle-xmark close-icon"></i>
            </button>
          )
          }
        </div>
        <p>DevEUI&nbsp;:&nbsp;<span>{deveui}</span></p>
        <p>
          {t(displaying === "temperature" ? "Temperature" : "CO2")}&nbsp;:&nbsp;
          <span>{current[field.value] || "-"}{field.unit}</span>
        </p>
        <p>
          {t(displaying === "temperature" ? "Temperature" : "CO2")} précédente&nbsp;:&nbsp;
          <span>{previous[field.value] || "-"}{field.unit}</span>
        </p>
        <p>Date&nbsp;:&nbsp;
          <span>
            {
              isOlderThan72Hours ?
                <>
                  <span style={{
                    color: "red",
                    fontWeight: "bold"
                  }}>{lastMessageDate ?? t("greater than 72h")}&nbsp;</span>
                  {isLoading && <Spinner width="16px" height="16px"/>}
                </>
                :
                <span>{moment.unix(current.ts).locale(lang).format("LLL")}</span>
            }
          </span>
        </p>
        <p>Emplacement&nbsp;:&nbsp; {zone} - {etage}</p>
        <a style={{display: "inline-block", margin: "5px 0"}} href="#" onClick={(event) => {
          event.preventDefault();
          setIsCurveModalOpen(true);
          const equipment = buildingContext.equipments?.find(e => e.name === deveui);
          if (equipment) {
            dispatch(selectSensors({default: [_.cloneDeep(equipment)]}));
            setEquipmentCurve(equipment);
            const building = buildingContext.buildings?.find(
              b => b.name.toLowerCase() == equipment.gui_informations.building.toLowerCase()
            );
            setBuildingIdCurve(building?.id);
          }
        }}><b>{t("See curve")}</b></a>
        <Modal
          open={isModalOpen}
          onClose={() => setIsCurveModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalCharts
              buildingId={buildingIdCurve}
              equipment={equipmentCurve}
              startDate={moment().subtract(24, "hours").format("YYYY-MM-DD HH:mm:ss")}
              endDate={moment().format("YYYY-MM-DD HH:mm:ss")}
              displayingValue={displaying}
            />

          </Box>
        </Modal>
        
        {
          !(lastMessageDate) && isOlderThan72Hours && (
            <PlainButton
              onClickHandler={() => fetchLastMessage(deveui)}
              text={t("Display date of the last message")}/>
          )
        }
      </div>
    </div>
  );
}
